<template>
    <div class="p-2">

        <!-- header -->
        <div class="d-flex align-items-center" style="gap:10px 20px">
            <div>
                <img :src="trainer.user_picture ? trainer.user_picture : default_img"
                    class="rounded-circle" style="height:80px;width:80px" />
            </div>
            <div>
                <h3 class="text-white h4 mb-0">{{trainer.user_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{trainer.trainer.trainer_title}}</h3>
            </div>
            <div class="ml-auto">
                <router-link :to="`/chats/${trainer.user_id}`">
                    <i class="bx bxs-message-dots" style="font-size: 28px;cursor: pointer;color: #FFAD32;"></i>
                </router-link>
            </div>
        </div>
        <!-- end header -->

        <div class="d-flex my-1">
            <div class="w-100 m-auto" style="background-color: #FFAD32;height: 2px;max-width: 150px;">
            </div>
        </div>

        <h2 class="text-white font-weight-bolder mt-2 text-center">{{training.tr_name}}</h2>
        <div class="d-flex mt-1 mb-2">
            <div class="w-100 m-auto" style="background-color: #FFAD32;height: 2px;max-width: 150px;">
            </div>
        </div> 
        
        <div v-for="(item,key) in schedules">
            <h4 class="text-white mb-1 mt-2">Schedule {{ moment(key).format('dddd, DD MMMM YYYY') }}</h4> 
            <router-link :to="`/training/payment/${time.trs_id}`" v-for="time in item">
                <div class="text-white d-flex align-items-center p-1 mt-1" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">{{ time.trs_time }}</div>
                    <div class="h4 text-white mb-0 ml-auto">Book Now</div>
                    <i class="bx bx-chevron-right" style="font-size: 20px;"></i>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'

export default {
    computed: {
        trainer(){
            return this.training?.owner
        },
        training(){
            return store.state.training.TRAINING
        },
        schedules(){
            return this.training?.schedules_ || []
        }
    },
    methods: {

    },
    mounted(){
        store.dispatch('training/GetTraining', this.id)
    },
    data(){
        return {
            id: this.$route.params.id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',
            moment
        }
    }
}

</script>